import React from 'react'
import styled, { css } from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'

const socialBlocksQuery = graphql`
  {
    settingsYaml {
      blogItems {
        image
        link
        isExternal
      }
    }
  }
`

const SocialBlocksContainer = styled.div`
  @media (max-width: 1240px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const LinkStyles = css`
  @media (max-width: 1240px) {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  @media (max-width: 768px) {
    margin: 0 0 1rem;
    width: 100%;
  }
`

const SocialBlockAnchor = styled.a`
  ${LinkStyles}
`
const SocialBlockLink = styled(Link)`
  ${LinkStyles}
`

const SocialBlockImage = styled.div`
  display: block;
  height: 330px;
  margin-bottom: 2rem;
  width: 100%;
  background: url(${props => props.image}) no-repeat center center;
  background-size: cover;
`

const SocialBlock = ({ item }) => {
  return item.isExternal ? (
    <SocialBlockAnchor href={item.link}>
      <SocialBlockImage image={item.image} />
    </SocialBlockAnchor>
  ) : (
    <SocialBlockLink to={item.link}>
      <SocialBlockImage image={item.image} />
    </SocialBlockLink>
  )
}

export const SocialBlocks = () => {
  const {
    settingsYaml: { blogItems },
  } = useStaticQuery(socialBlocksQuery)

  return (
    <SocialBlocksContainer>
      {blogItems.map((item, index) => {
        return <SocialBlock item={item} key={index} />
      })}
    </SocialBlocksContainer>
  )
}
