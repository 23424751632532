import { media } from '@caldera-digital/theme'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { useState } from 'react'
import { animated, config, useTransition } from 'react-spring'
import styled, { css, keyframes } from 'styled-components'

import ErrorIcon from '../../svgs/error.svg'
import WarnIcon from '../../svgs/warn.svg'
import { emailIsValid } from '../../utils/formUtils'
import { Button } from '../Button/Button'
import { ShareIcons } from '../ShareIcons/ShareIcons'

const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`
const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`
const fill = keyframes`
  100% {
    box-shadow: inset 0px 0px 0px 30px none;
  }
`

const INPUT_HEIGHT = '54px'
const INPUT_HEIGHT_MOBILE = '44px'
const BUTTON_SPACING = '0px'
const BUTTON_WIDTH = '120px'
const FORM_ERROR_SIZE = '16px'
const FORM_ERROR_SIZE_MOBILE = '14px'
const INPUT_COLOR = '#EAEAEA'

const Wrapper = styled.div`
  /* Fixed so there's no jank on success animation */
  height: 100px;
  position: relative;
  width: 100%;
`

const StyledEmailForm = styled.form`
  text-align: center;
  width: 80%;
  margin: 0 auto;

  ${media.forSmallOnly`
    width: 100%;
  `}

  ${props =>
    props.micro &&
    css`
      width: 100%;
    `}
`

const StyledFancyInput = styled.input`
  height: ${INPUT_HEIGHT};
  width: 100%;
  background: ${INPUT_COLOR};
  border: 1px solid
    ${({ theme, warn, error }) =>
      warn ? theme.warnColor : error ? theme.errorColor : INPUT_COLOR};
  /* border-radius: 3px; */
  padding-left: 20px;
  padding-right: ${`calc(${BUTTON_WIDTH} + ${BUTTON_SPACING} + ${BUTTON_SPACING})`};
  transition: ${props => props.theme.defaultTransition};

  ::placeholder {
    color: rgba(70, 70, 70, 0.5);
  }

  /* &:active, &:focus {
    background-color: rgba(70, 70, 70, 0.25);
  } */

  ${media.forSmallMediumOnly`
    height: ${INPUT_HEIGHT_MOBILE};
    font-size: 16px;
  `}

  ${props =>
    props.micro &&
    css`
      height: 35px;
      font-size: 0.9rem;
    `}

  ${props =>
    props.light &&
    css`
      background: ${props => props.theme.white};
    `}
`

const FancyInputWrapper = styled.div`
  position: relative;
  height: ${INPUT_HEIGHT};

  ${media.forSmallMediumOnly`
    height: ${INPUT_HEIGHT_MOBILE};
  `}
`

const StatusText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 17px;
  }

  p {
    font-size: ${FORM_ERROR_SIZE};
    color: ${props =>
      props.warn ? props.theme.warnColor : props.theme.errorColor};
    margin-bottom: 0;
  }

  ${media.forSmallMediumOnly`
    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }

    p {
      font-size: ${FORM_ERROR_SIZE_MOBILE};
    }
  `}

  ${media.forSmallOnly`
    svg {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    p {
      font-size: 12px;
    }
  `}
`

const FancyInputButton = styled(Button)`
  position: absolute;
  padding: 0;
  top: ${BUTTON_SPACING};
  bottom: ${BUTTON_SPACING};
  right: ${BUTTON_SPACING};
  height: ${`calc(${INPUT_HEIGHT} - ${BUTTON_SPACING} - ${BUTTON_SPACING})`};
  border: none;
  min-width: 140px;

  &:hover {
    background-color: ${props => props.theme.hoveredPrimaryColor};
  }
  &:active {
    background-color: ${props => props.theme.pressedPrimaryColor};
  }
  &:disabled {
    background-color: rgba(70, 70, 70, 0.25);
    cursor: auto;
  }

  ${media.forSmallMediumOnly`
    height: ${INPUT_HEIGHT_MOBILE};
    font-size: 16px;
    min-width: 130px;
  `}

  ${media.forSmallOnly`
    min-width: 110px;
  `}

  ${props =>
    props.micro &&
    css`
      position: relative;
      height: auto;
      padding: 0.4rem 0.6rem;
      display: block;
      margin: 0 auto;
      font-size: 0.9rem;
    `}

  ${props =>
    props.submitting &&
    css`
      pointer-events: none;
    `}
`

const SubmitSuccessWrapper = styled.div``

const SubmitSuccessState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${props => props.theme.black};
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: ${props => props.theme.defaultFontColor};
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #acacac;
    animation: ${fill} 0.4s ease-in-out 0.4s forwards,
      ${scale} 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  p {
    color: ${props => props.theme.defaultFontColor};
    font-size: 0.8rem;
    margin: 0 0 0 1rem;
  }

  ${props =>
    props.micro &&
    css`
      p {
        font-size: 0.75rem !important;
        margin: 0 0 0 0.5rem;
      }
    `}
`

const StyledLabel = styled.label`
  display: block;
  text-align: left;

  span {
    display: block;
    font-size: 1rem;
    letter-spacing: 0.43px;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${props => props.theme.white};
    margin-bottom: 10px;
  }

  ${props =>
    props.micro &&
    css`
      width: 100%;
      text-align: center;
    `}
`

const EmailFormShareIcons = styled(ShareIcons)`
  margin-top: 1rem;
`

export const EmailForm = ({ micro, light }) => {
  const [email, setEmail] = useState('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [showBumpCelebration, setShowBumpCelebration] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [result, setResult] = useState()

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)

    const result = await addToMailchimp(email.trim())

    setResult(result)
    setSubmitting(false)
  }
  const isSuccessful = result && result.result === 'success'

  const transitions = useTransition(!isSuccessful, null, {
    from: { position: 'absolute', width: '100%', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
  })
  const isEmailInvalid = !emailIsValid(email.trim())

  return (
    <Wrapper>
      {transitions.map(({ item, props, key }) =>
        item ? (
          <animated.div style={props} key={key}>
            <StyledEmailForm
              onSubmit={handleSubmit}
              micro={micro}
              light={light}
            >
              <StyledLabel micro={micro} light={light}>
                <span style={{ display: 'none' }}>Email Address</span>
                <FancyInputWrapper micro={micro} light={light}>
                  <StyledFancyInput
                    value={email}
                    placeholder="Email address"
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => setEmailTouched(true)}
                    warn={emailTouched && result && !isSuccessful}
                    error={Boolean(emailTouched && isEmailInvalid && !result)}
                    micro={micro}
                    light={light}
                  />
                  <FancyInputButton
                    disabled={isEmailInvalid}
                    micro={micro}
                    light={light}
                    submitting={submitting}
                  >
                    {submitting ? 'Loading...' : 'Subscribe'}
                  </FancyInputButton>
                </FancyInputWrapper>
              </StyledLabel>
              {emailTouched && isEmailInvalid && !result && (
                <StatusText>
                  <ErrorIcon alt="error" />
                  <p>Please enter a valid email address.</p>
                </StatusText>
              )}
              {result && (
                <StatusText warn>
                  <WarnIcon alt="warning" />
                  <p>
                    {isSuccessful
                      ? null
                      : 'Email is not valid or you have already subscribed, please try again.'}
                  </p>
                </StatusText>
              )}
            </StyledEmailForm>
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            <SubmitSuccessWrapper micro={micro} light={light}>
              <SubmitSuccessState>
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
                {showBumpCelebration ? (
                  <p>
                    <span role="img" aria-label="high five">
                      🙌🏻
                    </span>{' '}
                    Woohoo, thank you for sharing!
                  </p>
                ) : (
                  <p>Thank you for subscribing!</p>
                )}
              </SubmitSuccessState>
              <EmailFormShareIcons
                onShareWindowClose={async () => {
                  setShowBumpCelebration(true)
                }}
              />
            </SubmitSuccessWrapper>
          </animated.div>
        ),
      )}
    </Wrapper>
  )
}
