import 'normalize.css'

import React from 'react'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import {
  CalderaDigitalThemeConsumer,
  Container,
  media,
} from '@caldera-digital/theme'
import config from '../../data/SiteConfig'
import { Footer, Nav, HeroImage } from '../components/index'

const MOBILE_MARGIN = '2rem'
const MainContentContainer = styled(Container)`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  /* margin-bottom: 6rem; */

  h1 {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1.3rem;
  }

  h3 {
    margin-bottom: 1.2rem;
  }

  p {
    margin-bottom: 1.1rem;
  }

  ${media.forSmallOnly`
    margin-top: ${props =>
      `calc(${MOBILE_MARGIN} + ${props.theme.mobileHeaderHeight})`};

    ${props =>
      props.hideMobileHeaderHeight &&
      css`
        margin-top: ${MOBILE_MARGIN};
      `}
  `}
`

export const MainLayout = ({
  location,
  children,
  fluid = false,
  showFooterCTA = true,
  showLatestFromOurBlog = false,
  showFooter = true,
  heroImage,
  className = '',
}) => (
  <CalderaDigitalThemeConsumer>
    {theme => (
      <>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <meta
            name="p:domain_verify"
            content="a9d63659c1b5facfb0522cc6288890ea"
          />
          <html lang="en" />
        </Helmet>
        <Nav location={location} theme={theme} />
        {heroImage && <HeroImage image={heroImage} />}
        <MainContentContainer
          className={className}
          fluid={fluid}
          hideMobileHeaderHeight={!!heroImage}
        >
          {children}
        </MainContentContainer>
        {showFooter && (
          <Footer
            showFooterCTA={showFooterCTA}
            showLatestFromOurBlog={showLatestFromOurBlog}
            config={config}
          />
        )}
      </>
    )}
  </CalderaDigitalThemeConsumer>
)

export default MainLayout
