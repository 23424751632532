import React from 'react'
import { Follow } from 'react-twitter-widgets'

export const UserInfo = props => {
  const { expanded } = props
  const { userTwitter } = props.config
  return (
    <Follow
      username={userTwitter}
      options={{
        count: expanded ? true : 'none',
      }}
    />
  )
}
