import React from 'react'

export const HTML = ({ html }) => {
  return html.trim() !== '<h1></h1>' ? (
    <div
      style={{ margin: '2rem 0' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : null
}
