import React from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import styled from 'styled-components'
import config from '../../../data/SiteConfig'

const ShareIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin: 0 5px;
    cursor: pointer;
  }
`

export const ShareIcons = ({
  url: baseUrl = config.siteUrl,
  copy = config.siteDescription,
  description = config.siteDescription,
  title = config.siteTitle,
  urlParams = '?ref=ss',
  image = config.siteLinkPreview,
  className = '',
  style = {},
  onShareWindowClose = () => null,
}) => {
  const url = `${baseUrl}/${urlParams}`
  return (
    <ShareIconsContainer className={className} style={style}>
      <FacebookShareButton
        url={url}
        quote={copy}
        onShareWindowClose={onShareWindowClose}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        url={url}
        title={copy}
        description={`${description}: ${url}`}
        onShareWindowClose={onShareWindowClose}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <TwitterShareButton
        url={url}
        title={copy}
        hashtags={config.relevantHashtags}
        onShareWindowClose={onShareWindowClose}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} onShareWindowClose={onShareWindowClose}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <PinterestShareButton
        media={image}
        url={url}
        onShareWindowClose={onShareWindowClose}
      >
        <PinterestIcon size={32} round={true} />
      </PinterestShareButton>
      <RedditShareButton
        url={url}
        title={title}
        onShareWindowClose={onShareWindowClose}
      >
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      <EmailShareButton
        url={url}
        subject={title}
        body={`Check this out! ${url}`}
        onShareWindowClose={onShareWindowClose}
      >
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </ShareIconsContainer>
  )
}
