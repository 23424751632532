import { Container, media } from '@caldera-digital/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import site from '../../../data/SiteConfig'
import Logo from '../../svgs/logo-fill.svg'
import { EmailForm } from '../EmailForm/EmailForm'
import { createRoutes } from '../Nav/Nav'

export const latestBlogsQuery = graphql`
  {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [fields___date], order: DESC }
      filter: { fileAbsolutePath: { glob: "!**/authors/*" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    }
  }
`

const CopyrightContainer = styled.span`
  font-size: 0.8rem;
`

const StyledFooterWrapper = styled.footer`
  background-color: rgba(70, 70, 70, 0.08);
  padding: 5rem 0;
  position: relative;

  @media (max-width: 767px) {
    padding: 3rem 0;
  }

  ${CopyrightContainer} {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledFooter = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const FooterColumn = styled.div`
  width: 25%;
  padding: 0 1.5rem;
  min-height: 130px;
  border-left: 6px solid ${props => props.theme.secondaryColor};
  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    color: ${props => props.theme.defaultFontColor} !important;
    display: block;
    margin-bottom: 0.5rem;
  }

  ${media.forSmallMediumOnly`
    width: 33%;
  `}

  @media (max-width: 767px) {
    margin-bottom: 2rem;
    min-height: auto;

    a {
      text-align: center;
    }
  }
`

const LogoContainer = styled(FooterColumn)`
  text-align: center;
  border: none;

  svg {
    fill: ${props => props.theme.white};
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 250px;
  }

  ${media.forSmallMediumOnly`
    width: 33%;
  `}

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;

    svg {
      margin-bottom: 0;
    }
  }
`

const LinksContainer = styled(FooterColumn)`
  ${media.forSmallMediumOnly`
    width: 25%;
  `}

  @media (max-width: 767px) {
    border: none;
    width: 100%;

    a {
      padding: 2px 0;
      margin-bottom: 0.75rem;
    }
  }
`
const BlogsContainer = styled(FooterColumn)`
  ${media.forSmallMediumOnly`
    display: none;
  `}
`
const EmailContainer = styled(FooterColumn)`
  border: none;

  > p {
    text-align: center;
    font-size: 0.9rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
      padding: 8px 4px;
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 2px;
      border: none;
      max-width: 300px;
    }
  }

  ${CopyrightContainer} {
    display: none;
  }

  ${media.forSmallMediumOnly`
    width: 38%;
  `}

  @media (max-width: 767px) {
    width: 100%;
  }
`
const FooterCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem;
  max-width: 900px;
  margin: 0 auto;

  h2 {
    text-align: center;
    max-width: 500px;
    margin-bottom: 2rem;
  }

  > p {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  ${media.forSmallMediumOnly`
    padding: 6rem 2rem;
  `}

  ${media.forSmallOnly`
    width: 100%;
    padding: 1rem;
  `}
`

const Copyright = () => (
  <CopyrightContainer>{site.copyright}</CopyrightContainer>
)

export const CTA = () => {
  return (
    <FooterCTA>
      <h2>Be a part of my journey</h2>
      <p>Follow along for more travel and stories!</p>

      <EmailForm />
    </FooterCTA>
  )
}

export const Footer = ({ showFooterCTA = true }) => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(latestBlogsQuery)

  return (
    <>
      {showFooterCTA && <CTA />}

      <StyledFooterWrapper>
        <StyledFooter>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <LinksContainer>
            {createRoutes().map(({ route, text }) => (
              <Link to={route} key={route}>
                {text}
              </Link>
            ))}
          </LinksContainer>
          <BlogsContainer>
            {edges.map(({ node: blog }) => (
              <Link to={blog.fields.slug} key={blog.fields.slug}>
                {blog.frontmatter.title}
              </Link>
            ))}
          </BlogsContainer>
          <EmailContainer>
            <p>Subscribe for updates!</p>
            <EmailForm micro light />
          </EmailContainer>
        </StyledFooter>
        <Copyright />
      </StyledFooterWrapper>
    </>
  )
}
