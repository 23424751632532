/* eslint-disable import/no-commonjs */
const config = {
  siteTitle: 'The Making of Kate | One story at a time.', // Site title.
  siteTitleShort: 'The Making of Kate', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'The Making of Kate | One story at a time.', // Alternative site title for SEO.
  siteLinkPreview:
    'https://res.cloudinary.com/the-making-of-kate/image/upload/q_auto:good/v1573870359/linkPreview_uphpp5.png',
  siteUrl: 'https://www.themakingofkate.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  relevantHashtags: ['travelblogger', 'wanderlust'],
  siteDescription:
    'Writing, traveling, drinking bottles of wine, and trying to capture it all. Check out The Making of Kate for stories, travel advice and more.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  // siteFBAppID: '1825356251115265', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-77919467-1', // GA tracking ID.
  disqusShortname: 'themakingofkate', // Disqus shortname.
  postDefaultCategoryID: 'Blog', // Default category for posts.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  mailchimp:
    'https://gmail.us5.list-manage.com/subscribe/post?u=3b0f331389146e628c1b8fcc9&amp;id=7be6178879',
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  postsPerPage: 9, // Amount of posts displayed per listing page.
  userName: 'Kate Reilly', // Username to display in the author segment.
  userEmail: 'kitslit123@gmail.com', // Email used for RSS feed's author segment
  userTwitter: 'KatieReilly1', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Fairfax, VA', // User location to display in the author segment.
  copyright: `Copyright © ${new Date().getFullYear()}. Kate Reilly.`, // Copyright string for the footer of the website and RSS feed.
  themeColor: '#E2B39F', // Used for setting manifest and progress theme colors.
  backgroundColor: '#E2B39F', // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`

module.exports = config
