import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const PaginationContainer = styled.div`
  text-align: center;
  margin: 1rem 0 2rem;
  overflow-x: scroll;

  a {
    padding: 0 1rem;
  }
`

export const Pagination = ({ currentPageNum, pageCount, pathPrefix }) => {
  const prefix = pathPrefix ? `${pathPrefix}` : '/'
  const prevPage =
    currentPageNum - 1 === 1
      ? prefix
      : `${prefix === '/' ? '' : prefix}/${currentPageNum - 1}`
  const nextPage = `/${currentPageNum + 1}`
  const isFirstPage = currentPageNum === 1
  const isLastPage = currentPageNum === pageCount

  return (
    <PaginationContainer>
      {!isFirstPage && <Link to={prevPage}>Previous</Link>}
      {[...Array(pageCount)].map((_val, index) => {
        const pageNum = index + 1
        return (
          <Link
            key={`listing-page-${pageNum}`}
            to={
              pageNum === 1
                ? prefix
                : `${prefix === '/' ? '' : prefix}/${pageNum}`
            }
          >
            {pageNum}
          </Link>
        )
      })}
      {!isLastPage && <Link to={nextPage}>Next</Link>}
    </PaginationContainer>
  )
}
