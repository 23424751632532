import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media } from '@caldera-digital/theme'
import { capitalize } from '../../utils/utilityService'

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PostContainer = styled(Link)`
  border: 2px solid #e5e5e5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  width: calc(33% - 2rem);
  margin: 1rem;

  img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }

  ${media.forSmallMediumOnly`
    width: calc(50% - 2rem);
  `}

  ${media.forSmallOnly`
    width: calc(100%);
    margin: 2rem 0;
  `}
`

const PostContentContainer = styled.div`
  padding: 1.5rem;

  h3 {
    font-size: 1.5rem;
  }
`

const PostInfo = styled.p`
  color: ${props => props.theme.secondaryColor};
`
const PostExcerpt = styled.p`
  color: ${props => props.theme.defaultFontColor};
`

const Post = ({
  post: { cover, title, date, author, category, excerpt, path },
}) => {
  return (
    <PostContainer to={path}>
      <img src={cover} alt={title} />
      <PostContentContainer>
        <h3>{title}</h3>
        <PostInfo>
          {date} / {author.frontmatter.name} / {capitalize(category)}
        </PostInfo>
        <PostExcerpt>{excerpt}</PostExcerpt>
      </PostContentContainer>
    </PostContainer>
  )
}

export class PostListing extends React.Component {
  getPostList() {
    const postList = []
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        category: postEdge.node.frontmatter.category,
        author: postEdge.node.frontmatter.author,
      })
    })
    return postList
  }

  render() {
    const postList = this.getPostList()
    return (
      <PostsContainer>
        {postList.map(post => (
          <Post key={post.title} post={post} />
        ))}
      </PostsContainer>
    )
  }
}
