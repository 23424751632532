import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Nav as CalderaNav } from '@caldera-digital/nav'
import { media } from '@caldera-digital/theme'
import { Location } from '@reach/router'

import Logo from '../../svgs/logo-transparent.svg'
import LogoWithName from '../../svgs/logo-transparent-with-name.svg'

export const NavWrapper = styled.div`
  ${media.forSmallMediumOnly`
    display: none;
  `}
`
export const NavLogoSection = styled.div`
  padding: 1.75rem;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    line-height: 1;
    margin: 0;
    font-size: 48px;
    line-height: 1.5;
  }
`
export const LinksSection = styled.div`
  background-color: ${props => props.theme.primaryColor};
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavLink = styled(Link)`
  font-size: 1.25rem;
  color: ${props => props.theme.defaultFontColor} !important;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  text-align: center;
  text-transform: capitalize;

  ${props =>
    props.selected &&
    css`
      font-weight: bold;
      text-decoration: underline;
    `}
`

const LOGO_SPACING = '28px'
const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    height: calc(${props => props.theme.desktopHeaderHeight} - ${LOGO_SPACING});

    ${media.forSmallOnly`
      height: calc(${props =>
        props.theme.mobileHeaderHeight} - ${LOGO_SPACING});
    `};
  }
`

const StyledNav = styled(CalderaNav)`
  background-color: ${props => props.theme.white};
  transition: ${props => props.theme.defaultTransition};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px 0px;

  ${media.forLargeUp`
    display: none;
  `}

  a {
    margin-right: 1.25rem;
    color: ${props => props.theme.defaultFontColor} !important;
    font-weight: 400;
    text-transform: initial !important;

    &:after {
      background-color: ${props => props.theme.secondaryColor} !important;
    }
  }

  ${media.forSmallMediumOnly`
    a {
      margin-right: 0.8rem;
    }
  `}

  ${media.forSmallOnly`
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;

    a {
      margin: 0;
      font-size: 1.25rem
    }
  `}
`

export const createRoutes = (location = {}) => [
  {
    route: '/about',
    text: 'About',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/travel',
    text: 'Travel',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/on-writing',
    text: 'On Writing',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/lifestyle',
    text: 'Lifestyle',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  // Hidden based on edits from Katie
  // {
  //   route: '/stories',
  //   text: 'Stories',
  //   as: Link,
  //   // style: routeStyles,
  //   get selected() {
  //     return this.route === location.pathname
  //   },
  // },
  {
    route: '/portfolio',
    text: 'Portfolio',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/work-with-me',
    text: 'Work With Me',
    as: Link,
    // style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
]

export const Nav = ({ theme }) => {
  return (
    <Location>
      {({ location }) => {
        const routes = createRoutes(location)

        return (
          <>
            <NavWrapper>
              <NavLogoSection>
                <Link to="/">
                  <Logo />
                </Link>
                <p>THE MAKING OF KATE</p>
              </NavLogoSection>
              <LinksSection>
                {routes.map(({ route, text, selected }) => (
                  <NavLink to={route} selected={selected} key={route}>
                    {text}
                  </NavLink>
                ))}
              </LinksSection>
            </NavWrapper>
            <StyledNav
              routes={routes}
              hamburgerColor={theme.primaryColor}
              renderLeftSection={() => (
                <LogoContainer to="/">
                  <LogoWithName />
                </LogoContainer>
              )}
            />
          </>
        )
      }}
    </Location>
  )
}
