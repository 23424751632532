import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const ButtonStyles = css`
  min-width: 120px;
  height: 34px;
  font-size: 18px;
  padding: 0.5rem 1rem;
  border: none;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.white};
  transition: ${props => props.theme.defaultTransition};
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.hoveredPrimaryColor};
  }
  &:active {
    background-color: ${props => props.theme.pressedPrimaryColor};
  }
  &:disabled {
    background-color: rgba(70, 70, 70, 0.25);
    cursor: auto;
  }
`

export const Button = styled.button`
  ${ButtonStyles}
`

export const LinkButton = styled(Link)`
  color: ${props => props.theme.white} !important;

  ${ButtonStyles};
`
