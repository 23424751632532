import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;

  ${media.forSmallOnly`
    margin-top: ${props => props.theme.mobileHeaderHeight};
    height: 275px;
  `}
`

export const HeroImage = ({ image }) => {
  return <Image src={image} />
}
