import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import styled from 'styled-components'

const TagsContainer = styled.div`
  display: flex;
  margin: 2rem 0;
  flex-wrap: wrap;

  > * {
    display: block;
    padding: 0.5rem 1rem 0 0;
  }
`

export const PostTags = ({ tags }) => (
  <TagsContainer>
    <span>Tags:</span>
    {tags &&
      tags.map(tag => (
        <Link
          key={tag}
          style={{
            textDecoration: 'none',
          }}
          to={`/tags/${_.kebabCase(tag)}`}
        >
          {tag}
        </Link>
      ))}
  </TagsContainer>
)
