import React, { Component } from 'react'
import { Disqus as BaseDisqus } from 'gatsby-plugin-disqus'
import urljoin from 'url-join'
import config from '../../../data/SiteConfig'

export class Disqus extends Component {
  render() {
    const { postNode } = this.props
    if (!config.disqusShortname) {
      return null
    }
    const post = postNode.frontmatter
    const url = urljoin(config.siteUrl, config.pathPrefix, postNode.fields.slug)

    const disqusConfig = {
      url,
      identifier: url,
      title: post.title,
    }

    return <BaseDisqus config={disqusConfig} />
  }
}
